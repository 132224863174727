.selection-panel {
  background-color: $bg-lighter-grey;
  padding: 24px;
  font-family: $font-circular-medium;
  border-radius: 0 0 40px;
  position: relative;

  @media (max-width: 767px) {
    border-radius: 0 0 24px;
  }

  &.white {
    background-color: $brand-white;
  }

  .l-container {
    display: flex;
    flex-direction: row;

    @media (max-width: 1023px) {
      flex-wrap: wrap;
      margin: 0 auto;
      max-width: 720px;
      width: 100%;
    }

    @media (max-width: 767px) {
      flex-direction: column;
    }
  }

  &-list-container {
    height: 150px;
    width: 100%;

    @media (max-width: 1023px) {
      height: unset;
    }

    ul {
      display: flex;
      list-style: none;
      padding: 0;
      margin-inline-start: -110px;
      margin-top: 40px;
      transition:
        width ease-out 0.65s,
        margin ease-out 0.4s;
      width: calc(100% + 110px);

      @media (max-width: 1023px) {
        margin: 10px 0;
        flex-wrap: wrap;
        width: 100%;
      }

      @media (max-width: 767px) {
        margin: 0;
        margin-inline-start: 0;
      }

      li {
        display: block;
        width: calc(33.33% - 24px);
        min-width: fit-content;
        margin-inline-end: 24px;
        margin-top: 0;
        transition: width ease-in-out 0.25s;

        &:last-child {
          margin-inline-end: 0;
        }

        @media (max-width: 1023px) {
          width: calc(50% - 24px);
          margin-bottom: 16px;
        }

        @media (max-width: 767px) {
          display: block;
          width: 100%;
          margin-bottom: 16px;
          margin-inline-end: 0;
          font-family: $font-circular-medium;
          overflow: hidden;
        }

        .selection-card {
          border: 1px solid $cool-greylight;
          border-radius: 8px;
          background-color: $brand-white;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          transition: width ease-in-out 0.5s;
          font-family: $font-circular-medium;
          overflow: hidden;

          &-image {
            background: $cochlear-lightyellow;
            border-radius: 8px 0 0 8px;
            min-width: 64px;
            height: 64px;
            position: relative;

            img {
              height: auto;
              width: auto;
              max-height: 32px;
              max-width: 48px;
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
            }

            &::before {
              content: "";
              background: $cochlear-lightyellow;
              width: 100%;
              height: 145px;
              position: absolute;
              top: -40px;
            }
          }

          &-title {
            margin-inline-end: auto;

            p {
              font-size: 21px;
              line-height: 28px;
              color: $font-darkblue;
              padding-inline-start: 16px;
              font-family: $font-circular-medium;
            }
          }

          &-link {
            font-size: 18px;
            line-height: 28px;
            color: $cochlear-purple;
            text-decoration: none;
            padding: 0 8px;
            font-family: $font-circular-medium;
            cursor: pointer;
          }

          &:hover {
            border: 1px solid $cochlear-purple;

            .selection-card-image {
              background-color: $bg-compatibility-textcolumn-yellow;
            }

            .selection-card-title {
              p {
                color: $cochlear-purple;
              }
            }

            .selection-card-link {
              color: $clr-magenta-blue;
            }
          }
        }
      }
    }
  }

  &.is-sticky {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 999;

    @media (max-width: 1023px) {
      padding: 0 24px;
    }

    .l-container {
      justify-content: space-between;

      .selection-panel-list-container {
        height: auto;

        ul {
          margin: 0;
          width: 100%;

          li {
            width: fit-content;

            .selection-card-title {
              width: calc(100% - 64px);
              transition: width ease-in-out 0.75s 1s;
            }

            .selection-card-link {
              opacity: 0;
              width: 0;
              transition:
                width ease-in-out 0.25s,
                opacity ease-in-out 0.25s;
            }
          }
        }
      }

      .selection-panel-text {
        opacity: 0;
        height: 0;
        transition:
          height ease-in-out 0.75s,
          opacity 0.25s;
        margin: 0;
      }

      .selection-panel-start-over-link {
        white-space: nowrap;
        display: flex;
        align-items: center;
        padding-bottom: 0;
        padding-inline-end: 24px;
        transition: all ease-in-out 0.5s;

        @media (max-width: 1023px) {
          width: 30px;

          span {
            display: none;
          }
        }

        .start-overIcon {
          margin-bottom: 0;
        }
      }

      .selection-panel-edit-link {
        color: $cochlear-purple;
        display: flex;
        align-items: center;
        text-decoration: none;
        font-size: 18px;
        line-height: 28px;
        min-width: 80px;
        opacity: 1;
        transition:
          opacity ease-in-out 0.5s,
          width ease-in-out 0.25s 0.5s;

        @media (max-width: 1023px) {
          min-width: 30px;
          padding-bottom: 0;

          span {
            display: none;
          }
        }

        .edit-icon {
          background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAGcSURBVHgB5ZXNUcMwEIV3ZR845hDuOVBAuHFgwCmBCoI7CA0QEwogqSBJB+4A00FKcAFk8DEzxFp2he2xHedH8YnhXSRL1ves1a4M8NflQEvddILe1YUH8SbaNM0jnCmPweQ6cwDyMlSE29SPkiBubWDgjnrn1T0gSDJSh/sxpvqaTZL8XQWWqsCFi/pBoAKXMe3iqPy+lUEdLiJSc2k14FM21C+vceEcuIQCtW/g/CzjCnViIo68E9sd7MBTPYg+g0jaPDRM7ktffdPUyqARnmWKaZF+D7Q2d5LBIbjo/nIyz7+8CS7CVnCCx0PwvQbH4HfdyRsvHEkNZHkf7/tQZQ9/HZfgg0PwRoNaEfm7cApK8BUcUcXgtht49SKSHVXgYgzG+ChcVCk0h9TQnApBCFKRWRFxzEOGmysACfxoHYRwouoh8gwE9axcRCbmBfx5ARYqDIrwCHQLK+06Qx7u5PO8gxdbuKgIUREeCYurvhiYJTHf80TLaD1ewBkqn4FX9DhLCGipkEK5c6CFqreppB/SDFKafpR+Gv9bP9urCkNncdZCAAAAAElFTkSuQmCC");
          background-repeat: no-repeat;
          background-position: center center;
          width: 24px;
          height: 24px;
          display: inline-block;
          margin-bottom: -4px;
          transition: all ease-in-out 0.5s;
        }
      }

      @media (max-width: 1023px) {
        flex-direction: row;
        justify-content: unset;

        .selection-panel-list-container {
          width: calc(100% - 30px);

          ul {
            position: relative;
            overflow: hidden;
            transition: width ease-in-out 0.75s;

            li {
              display: flex;
              position: relative;
              max-width: 30%;
              align-items: center;
              margin: 8px 0;
              transition: margin ease-in-out 0.75s;

              @media (max-width: 767px) {
                max-width: 33%;
              }

              &:not(:first-child)::before {
                content: "";
                width: 5px;
                height: 5px;
                border-radius: 50%;
                background-color: $cochlear-purple;
                display: block;
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                transition: all ease-in-out 0.5s;
              }

              &:nth-child(1) {
                transition: width ease-in-out 0.5s;
              }

              &:nth-child(2) {
                transition: width ease-in-out 0.5s 0.5s;
              }

              &:nth-child(3) {
                transition: width ease-in-out 0.5s 0.75s;
              }

              .selection-panel-text {
                opacity: 0;
                width: 0;
                height: 0;
              }

              .selection-card {
                background-color: transparent;
                border: none;
                transition: all ease-in-out 0.5s;

                &-image {
                  display: none;
                  transition: all ease-in-out 0.5s;
                }

                &-title {
                  width: 100%;
                  transition: all ease-in-out 0.5s;

                  p {
                    font-size: 18px;
                    margin: 0;
                    margin-inline-start: 5px;
                    transition: all ease-in-out 0.5s;

                    @media (max-width: 570px) {
                      font-size: 16px;
                    }

                    @media (max-width: 440px) {
                      font-size: 14px;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  &-start-over-link {
    padding-bottom: 24px;
    color: $cochlear-purple;
    display: inline-block;
    text-decoration: none;
    white-space: nowrap;
    font-size: 18px;
    line-height: 28px;
    transition: all ease-in-out 0.5s;

    .start-overIcon {
      background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAUCAYAAACEYr13AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAGNSURBVHgBnVM7TsNAEJ1ZR5ECjSXcg0+ASwoULx3HyA3CCfAmHADlBrR05ARZKGjxDXBPkEyDIoXsMGNjyVYSJ+Q1Ozu7781nZwFa0A/uEtgB1UZGIAOHCOxL3ijwH7IA9yNjCkQprtzI5ibbmoECh7ARFHGoAXXUez8Y32vf+NWJV7+WfVsbHmkWQV35nue3GHbjKSH0EDHiwwuOdB129WO2sIu1Hth5YriMUcOXm/TlMxngjwuBIOMAkeuoZK0HdehgZAgwkQwaft+ckafemOkjuCtvm0BVjqwN/8Lmp8e6h1wml/W1NYM26MBoAjXjLLKDBARxMCZZlRjV5hAoSaNKa18SNzIqLUoVOZqK6Wpvvwv8CsPSwFQppCexuatDeaJd5OIOT2XB4dFWdm4sEU1477PyrE3kbwZmBRlpIv+iIxu1IkMdiNkbybzHJ+OHpXKT1w+Tyvkl90eBF3OgGxkgqR2W5afDmrrvPDQ878O2EiSykDl63hCop1kKqfPiF5a3Mmm29EtKrt//BaH3qp6V/J7VAAAAAElFTkSuQmCC");
      background-repeat: no-repeat;
      background-position: center center;
      width: 24px;
      min-width: 24px;
      height: 24px;
      display: inline-block;
      margin-bottom: -4px;
      margin-inline-end: 4px;
    }
  }

  &-text {
    font-family: $font-circular-medium;
    font-weight: 500;
    font-size: 18px;
    line-height: 28px;
    color: $font-darkblue;
    margin: 0 0 8px;
    transition: all ease-in-out 0.5s;
  }

  .selection-panel-edit-link {
    opacity: 0;
    width: 0;
  }
}
