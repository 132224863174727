.processor-listing-container {
  margin-top: 24px;

  .processor-product-gallery-container {
    display: grid;
    gap: 12px;
    grid-template-columns: repeat(2, 1fr);

    @media only screen and (min-width: 768px) {
      gap: 24px;
      grid-template-columns: repeat(4, 1fr);
    }

    .product-gallery-card {
      border: 1px solid $bg-dark-grey;
      border-radius: 4px;
      cursor: pointer;
      overflow: hidden;

      .product-gallery-image {
        background-color: $cochlear-lightyellow;
        padding: 12px;

        img {
          height: auto;
          width: 100%;
          transition: transform ease-in-out 0.3s;
        }
      }

      .product-gallery-content {
        padding: 16px 8px 21px;
        text-align: center;

        .product-gallery-name {
          font-size: 18px;
          line-height: 28px;
          font-weight: 500;
          font-family: $font-circular-medium;
          color: $font-darkblue;
        }

        .product-gallery-details {
          font-size: 16px;
          line-height: 24px;
          font-weight: 400;
          font-family: $font-circular-regular;
          color: $font-darkblue;
        }
      }

      &:hover {
        border: 1px solid $link-colour;

        .product-gallery-image {
          background: $bg-light-yellow;

          img {
            transform: scale(1.2);
          }
        }

        .product-gallery-name,
        .product-gallery-details {
          color: $link-colour;
        }
      }
    }
  }
}
