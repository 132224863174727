.alert-wrapper {
  @include fullwidth-component;

  align-items: start;
  column-gap: 16px;
  display: flex;
  min-height: 60px;
  padding: 16px;

  @media only screen and (min-width: 48em) {
    align-items: center;
    padding: 16px 104px;
  }

  &.bg-grey {
    background-color: $bg-light-grey-1;
  }

  &.bg-yellow {
    background-color: $bg-light-yellow;
  }

  &.bg-blue {
    background-color: $bg-light-blue;
  }

  .alert-content * {
    font-family: $font-circular-regular;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    padding: 0;
  }

  .alert-content {
    flex: 1 1 100%;

    @media only screen and (min-width: 64em) {
      align-items: baseline;
      display: flex;
      max-width: calc(100% - 64px);

      .alert-content-text {
        line-height: 24px;
      }
    }
  }

  ul {
    padding-left: 0;
    padding-inline-start: 0;

    li {
      min-width: 112px;
      color: $cochlear-black;
      list-style-type: none;
      position: relative;

      &:not(:last-child) {
        margin-inline-end: 12px;
      }

      &::before {
        content: "";
        width: 7px;
        height: 7px;
        background-color: $cochlear-black;
        border-radius: 100%;
        display: inline-block;
        margin-inline-end: 12px;
        margin-bottom: 3px;
        vertical-align: middle;
      }
    }

    @media only screen and (min-width: 64em) {
      display: flex;
      margin-inline-start: 24px;

      li {
        margin-top: 0;
      }
    }
  }

  img,
  button {
    margin-top: 4px;
  }

  button {
    width: 13.18px;
    height: 13.18px;
    border: none;
    background-color: transparent;
    background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA4AAAAOCAYAAAAfSC3RAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAADdSURBVHgBjZKxDYMwEEU5jJCgg4qSVdiANdJ5g2QEVmGCrJF0LikpKBAF5F8UR8Y5W/kSkvl3zz6fTzVN01ZVdS3L8rksy5xExLl1XeuiKIzC4kZEWinVwxhDMEN5nt+x7JFPKTTgx+B7BzghAnHMZFk2EAdaaN/3b2Dbtm6aJiNBOKgzENldJZh9CWKf3JJ82No+9AMKcCJBrNQH13VN/vFOJ/qNcEt1G3YCpe6xH+o2hSB7p9BTUQyykuAUkI5BLPY+pXOsxeRohQF/HMfBs3eRIKsZQu7IuZjr4QVBI6FAHyz5ngAAAABJRU5ErkJggg==");
    background-size: 100%;
    outline: none;
  }
}
