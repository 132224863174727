.device-section {
  display: grid;
  gap: 12px;
  grid-auto-rows: 176px;
  grid-template-columns: repeat(2, 1fr);
  margin-top: 24px;

  @media only screen and (min-width: 768px) {
    gap: 24px;
    grid-auto-rows: 194px;
    grid-template-columns: repeat(4, 1fr);
  }

  .device-card {
    align-items: center;
    background-color: $cochlear-lightyellow;
    border-radius: 8px;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .device-image {
      align-items: center;
      display: flex;
      height: 40px;

      img {
        height: 40px;
        width: auto;
      }
    }

    .device-name {
      color: $font-darkblue;
      margin: 16px auto 0;
      padding: 0 4px;
      font-size: 18px;
      line-height: 28px;
      font-weight: 500;
      font-family: $font-circular-medium;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      text-align: center;
    }

    &:hover {
      background-color: $bg-compatibility-textcolumn-yellow;

      img {
        filter: invert(8%) sepia(95%) saturate(6314%) hue-rotate(266deg)
          brightness(47%) contrast(100%);
      }

      .device-name {
        color: $cochlear-purple;
      }
    }

    &:focus-visible {
      outline: 2px solid #45b7f8;
    }
  }
}

.model-section {
  display: flex;
  flex-direction: column;
  font-family: $font-circular-regular;
  width: 100%;

  .model-select-input {
    height: 52px;
    border: 1px solid #707071;
    padding: 12px 16px;
    color: $cochlear-lightgrey;
  }

  .model-select-list {
    border: 1px solid $cochlear-lightgrey;
    margin-top: 8px;

    .model-item {
      padding: 12px 16px;
      font-size: 18px;
      line-height: 28px;
      font-weight: 400;
      color: $font-darkblue;
      outline: none;
      position: relative;

      &:hover {
        background-color: #ebebeb;
        cursor: pointer;
      }
    }
  }

  .no-device-message {
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 28px;
  }
}
