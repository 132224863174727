.title-with-link {
	margin: 32px auto;
	width: 100%;

	@media only screen and (min-width: 64em) {
		margin: 48px auto;
		width: 764px;
	}

	.title-with-link-content {
		padding: 24px;
		background-color: $bg-lighter-grey;
		border-radius: 8px;
		float: left;
		width: 100%;
		display: flex;
		justify-content: space-between;
		margin-bottom: 48px;

		@media only screen and (max-width: 63.99em) {
			display: block;
			float: none;
			margin-bottom: 32px;
		}

		.title {
			font-weight: 500;
			font-size: 21px;
			line-height: 32px;
			color: $font-darkblue;
			font-family: $font-circular-medium;
			display: flex;
			align-items: center;

			@media only screen and (max-width: 63.99em) {
				width: 100%;
				margin-bottom: 16px;
			}
		}

		a {
			border: 1px solid $cochlear-purple;
			border-radius: 16px;
			gap: 12px;
			background-color: transparent;
			width: 100%;
			color: $cochlear-purple;
			font-family: $font-circular-medium;
			font-size: 14px;
			font-weight: 500;
			line-height: 24px;
			padding: 12px 20px !important;
			text-align: center;
			transition: color 0.3s ease;

			&:hover {
				border: 2px solid $clr-magenta-blue;
				background-color: transparent;
				color: $clr-magenta-blue;
				padding: 11px 19px !important;
			}

			&::after {
				display: inline-block;
				left: 5px;
				top: 5px;
				position: relative;

				div#root > [dir='rtl'] & {
					left: -5px;
					right: unset;
				}
			}

			@media only screen and (min-width: 64em) {
				font-size: 16px;
				line-height: 20px;
				padding: 12px 28px !important;
				text-align: start;
				width: unset;

				&:hover {
					padding: 11px 27px !important;
				}

				&::after {
					display: none;
				}
			}
		}
	}

	.title-with-link-details {
		font-size: 14px;
		line-height: 20px;
		font-weight: 400;
		color: $cochlear-black;
	}
}
