.feature-listing-container {
  .section-title {
    color: $font-darkblue;
    font-family: $font-circular-medium;
    font-size: 28px;
    font-weight: 500;
    line-height: 36px;
    margin-bottom: 24px;
    margin-top: 64px;
  }

  .accordion-container {
    border: 1px solid $cool-greylight;
    border-radius: 4px;
  }

  .feature-accordion-item:not(:first-child) .accordion-item-title {
    border-top: 1px solid $mid-grey;
  }

  button.accordion-trigger {
    align-items: center;
    background-color: transparent;
    border: none;
    color: $cochlear-purple;
    cursor: pointer;
    display: flex;
    font-family: $font-header;
    font-size: 18px;
    margin: 0;
    outline: none;
    padding: 16px;
    width: 100%;

    img {
      display: block;
      height: 48px;
      margin-inline-end: 16px;
      width: 48px;
    }

    &::before {
      background-size: 100%;
      content: "";
      flex-shrink: 0;
      height: 21px;
      margin: auto 0;
      margin-inline-end: 16px;
      width: 20px;
    }

    &::after {
      background-image: sax(Chevron, $cochlear-purple);
      background-size: 100%;
      content: "";
      width: 24px;
      margin: auto 0;
      height: 24px;
    }

    &.active::after {
      transform: rotate(180deg);
    }
  }

  /* compatible or not compatible icons */
  .accordion-container.compatible .accordion-trigger::before {
    background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAZCAYAAAArK+5dAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAJXSURBVHgB3VXLbtNAFD1ju2LTRYTYM2pgTVgigXB3LJsduyR/kCVqjeJKbcQyfEGbLyA7lg3iA2rWEMl8QY0ACamxh3vHj9rJ2HWXcBaxnbn3nLkz584A/zpEq6hD2QPsHiwl9XecBJQZYhoGt6U2C3h7EwoZ01unJjtEjGNMV+e4k8AbKeFYH2i4l/0TUeQCSn3P0p7Qew9CyELoOt7HuzC8XUCT25d61ooShRjh5NsSJhx2hxBqooV0NVYf069BvQCT71gXUJSgMMfveIxZGKEJvuzg2p4R00CL/IyflnOcSvCOPSFiSW9LnK6GaANfkw3hdR9Srotd+4y++/mwVZm9okDGOh6hLcayU8phsQN4j9xtAcca66fCuWmzjDjqntGMr7SNdY6apwPJwbYAO0M/xBxtwOSCKmYjJMjW3Fpkk3xpEEBqyV/rGxfk5TeRxyV7Ollubt8NgZQsdwBbkMvXzdaCnOEX7umYBKLKrC2RJQq/EGki1wJFxZFBQKXBu066VNxcCUaFyNHeZSM5Y4288wODAD6ljxsH6DMmFxF0bDSR6xh7kE32i0EgcwDEQPfEtsiikbzSR8ms0KwEeV064MAVLHGy2sdd4HUv6NfVfXS6GhkqIDjUjUrvhYu3tKF1Ni2DY3jzU3JewuPysF0JXkZ/8OIB7UXyiopzcc96jef3f+Dzlfli8STHfNSx7Jw4fra5hPX3gU2navm8V+yMbPOE4IONlzKtUKmAbrl+u/ugjPJ5bwb5XbwnS/t1FC3v5Md0HyeSppr6POEmtILNy+X/xF/Jlv5oPsuwTwAAAABJRU5ErkJggg==");
  }

  .accordion-container.not-compatible .accordion-trigger::before {
    background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAVCAYAAABG1c6oAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAJlSURBVHgBlVVNbtNQEJ55jpCpWOQGNSfALCqyq7NAarMKJ2gq0TY70hPEPUHLjraLJiegqxKJRdxdpG7CCTA3yAKBkRoPM88/PP/EwCzs55n3vjfzzY8RGmTn9ZFrWeACoJNoKFyvYfnw+Xq56QzWKTu94zERjBCwveFcCBSfLWbXk0bAzt7QAaQ5Lx3tD9AKEW8hpm/6G+EFX+Jm9gQYu4vZh7ACuNM7chXhPPVKNh7yxgDqItgbDvjisQAzwOrxMe5mNGDFM6KpHW2NguBiBQ3i9gdt+9eTC4Y4MD1taavi20iHESxmVwP4B1neTuTCQWf/ZJvfHiGd8/uN0t4RJCAcZjG0tx5Uwi3p+IzmGqAvNoVIo8QAE5PcTm84BrTmkvEmnZxRgFNZI1r9Vgywm2RmPS3cHDO/qD3wGUB44TX5uc10krgSkN7FQLuyyxGlHT0rFCvf7DPYWRqWn4MRcf1d+ebeyI70WfbUUVnx1mVVg7LJUAVlMJE0QcwatJUQKh+eN6p0heaMTYbKM/nLREpI3lKTHDKGidvf3QqYEaYZfhnUjmx9ljkMW4x4z666kqFCeHlS/nDGJQa6Q0pJ4To+kHiZw3vkTZ50ibjL2XpZKB22lduvrEu77Gvq/XN906v944/sjvZw8emyC/8h3CnSsl5Sx5eHuvWQ1Ck/hAcm/eTG/vH09G+9LEmMtn6eM5AH0suQcNwwbernXRImtx9aN7x0pEpipO7DnTFtSnzk81CAmeslEn3RXwq3iahvDN7N87AEnM+7OrseBqTep4VfkFrATIQGKy79UxT/U+42/1N+A+laM3NFRs1nAAAAAElFTkSuQmCC");
  }

  .accordion-trigger-title {
    align-self: center;
    flex: 1;
    text-align: start;
  }

  .accordion-item-description:not(.expanded) {
    display: none;
  }

  .accordion-item-description .accordion-panel {
    border-top: 1px solid $cochlear-yellow-New;
    padding: 24px;
    padding-inline-start: 48px;

    /* rich text container */
    div {
      * {
        color: $font-darkblue;
        font-family: $font-circular-regular;
        font-size: 18px;
        font-weight: 400;
        line-height: 28px;
      }

      a {
        color: $cochlear-purple;

        &:hover {
          color: $clr-magenta-blue;
        }
      }

      a.cta,
      a.cta-button {
        font-family: $font-circular-medium;
        font-weight: 500;

        &:has(+ a) {
          margin-bottom: 8px;
          margin-inline-end: 16px;

          @media only screen and (max-width: 48em) {
            margin-inline-end: 0;
          }
        }
      }

      a.cta {
        /* clear framework styles */
        border: none;
        padding: 0;
        text-align: unset;
        text-decoration: none;

        &:active,
        &:focus,
        &:hover {
          background-color: inherit;
        }
        /* end framework styles */

        /* external link icon */
        &::after {
          background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAE7SURBVHgB7VTdTcMwEP7OpO+R2ryHDRgAlKzQDdwRukBxYQE2oEwAG2CUBboBeU8r5R3w4YQW0TZOHCokHvpJfjnf3Xc/nw38MaiP8+VIpUSIDy4Mymytno4iSEMVcyBeXfeGMcnWs8W+XcATulQ5sRkTmcn22PLyrrjgu7qBeHZ6MZYvq9lY/xhDEt3cW3tsT46mse10MEBaOxuEzW60Y98kl0T8QMLM0dXBFgaYZsXhHF3JdXEt00jJNn/vHTQl94nxJnAm569Fn8GUvyZoq1yvlKZ3c64d76CTwJU8Gd4+JqN5rbxKwq74oC15OlSSXTMXfOHzTts7+IAmg6nvQpvQ2sGm9TscgV4y/ZcEwR5bchUpeKH6VgTKXgRWFFKwkPBBJSDmvMutJtCFWlhJlraiEH3whiVOOOETBr2KM5/mSf8AAAAASUVORK5CYII=");
          content: "";
          display: inline-block;
          height: 24px;
          margin-inline-start: 4px;
          vertical-align: bottom;
          width: 24px;

          [dir="rtl"] & {
            transform: scaleX(-1);
          }
        }
      }

      a.cta-button {
        background-color: $brand-white;
        border: 1px solid $cochlear-purple;
        border-radius: 14px;
        font-family: $font-circular-medium;
        font-weight: 500;
        padding: 10px 24px;
        padding-inline-end: 56px;

        &:hover {
          border: 1px solid $clr-magenta-blue;
          background: transparent;
        }

        &::after {
          background-image: sax(Chevron, $clr-magenta-blue);
        }
      }

      ul {
        padding-inline-start: 0;
        padding-left: 0; /* override framework */

        li {
          color: $font-darkblue;
          display: inline-flex;
          font-size: 18px;
          font-weight: 400;
          line-height: 28px;
          list-style: none;
          padding-inline-start: 16px;
          position: relative;
          width: 48%;

          &:only-child {
            width: 100%;
          }

          @media only screen and (max-width: 48em) {
            width: 100%;
          }

          &::before {
            content: "\2022";
            display: inline-block;
            font-weight: bold;
            padding-inline-end: 27px; /* includes width of bullet */
            width: 11px;
          }
        }
      }
    }
  }
}
