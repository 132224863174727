.compatibility-global-footnotes {
  @include fullwidth-component;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 104px;
  background-color: $bg-footnotes-grey;

  @media only screen and (max-width: 63.99em) {
    margin-top: 80px;
  }

  span {
    font-family: $font-circular-medium;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    display: block;
    margin-bottom: 8px;
  }

  .footnotes-content {
    padding: 56px 104px;
    max-width: 1366px;
    margin: 0 auto;

    div {
      font-family: $font-circular-regular;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;

      .footnotes-title {
        font-family: $font-circular-medium;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        color: #404042;
      }

      div {
        margin-bottom: 8px;
      }

      span {
        margin-top: 40px;
        display: block;
      }
    }

    @media only screen and (max-width: 63.99em) {
      padding: 40px 24px;
    }
  }
}
